import { graphql } from 'gatsby'
import Image, { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'
import { GraphQLQuery } from '../types'

const mockData = {
  content: `
    Spending time at national parks can be an exciting adventure, but this wasn't the type of excitement she was hoping to experience. As she contemplated the situation she found herself in, she knew she'd gotten herself in a little more than she bargained for. It wasn't often that she found herself in a tree staring down at a pack of wolves that were looking to make her their next meal.
    Greg understood that this situation would make Michael terribly uncomfortable. Michael simply had no idea what was about to come and even though Greg could prevent it from happening, he opted to let it happen. It was quite ironic, really. It was something Greg had said he would never wish upon anyone a million times, yet here he was knowingly letting it happen to one of his best friends. He rationalized that it would ultimately make Michael a better person and that no matter how uncomfortable, everyone should experience racism at least once in their lifetime.
    His parents continued to question him. He didn't know what to say to them since they refused to believe the truth. He explained again and again, and they dismissed his explanation as a figment of his imagination. There was no way that grandpa, who had been dead for five years, could have told him where the treasure had been hidden. Of course, it didn't help that grandpa was roaring with laughter in the chair next to him as he tried to explain once again how he'd found it.
    `,
  description: 'He appeared to be confusingly perplexed.',
  author: 'Bobby Brown',
  title: 'How I Made $120,000 in My First Year as a Freelance Exorcist'
}

type Props = {
  file: {
      name: string
      childImageSharp: GatsbyImageFluidProps
  }
}
const ArticlePage = ({ data: { file } }: GraphQLQuery<Props>) => {

  return (
    <div className="bg-gray-50 text-gray-600 pt-8">
        <Image fluid={file.childImageSharp.fluid} />
      <div className="max-w-lg mx-auto">
        <div className="px-2 md:px-0 pb-12">
          <h1 className="text-gray-800 font-bold text-4xl">
            {/* {mockData.title} */}
            Feeling stuck as a first-year during the pandemic 
          </h1>
          <p className="italic pt-12 text-gray-500">
            {/* {mockData.description} */}
            A letter on the loneliness of watching my friends leave while I stayed behind 
          </p>
          <p className="font-semibold text-gray-700 pt-12">
          Anastasia Kasirye
            {/* {mockData.author} */}
          </p>
        </div>
        <div className="px-4 md:px-0 pt-12">
          <p className="text-sm leading-8 font-normal">
            {/* {mockData.content} */}
            <p>
A letter to myself: 
</p>
<p>
It’s sometime between late 2020 and early 2021. And somehow, even when the whole world has assumed a pause and most are at home behind perpetually closed doors, even when everyone’s not doing much and longing for the pre-COVID-19 abundance of interaction and activity — you’re missing out. You’ve done it again: you’re stuck.
</p>
<p>
While all of your friends stream along — going out, making new friends, surpassing their goals — you remain here, close to it all, but off at a tangent. Near enough to moan about all of the assignments and the short deadlines, to carry along tutorial discussions with your helpful — but perhaps not as developed — insights, to laugh with the new group and go to their socials, but never managing to make them your friends. 
</p>
<p>
You are certainly not the only one feeling this way. You struggle to derive a semblance of comfort from that because, somehow, amidst all of the disconnection, disorientation, and disarray of plans wrought by the upheaval brought about by the pandemic, people are still seamlessly connecting and embarking on new, vibrant chapters in their lives. Growing. Thriving. 
</p>
<p>
Where are you in all of this? Are you still on that precipice of fresh experiences, where the exhilaration you seek is so close but also so far that, well, how do you even get there? Maybe “stuck” was the wrong word to use. You’re lost.
</p>
<p>
Lost in the throes of an interim personal fable you had told myself while knowing, but not really feeling, that there were people — many of them — who could probably relate. 
</p>
<p>
The truth is, when your friends left because their universities were open and in-person, you felt like you had no life. They trickled off, one by one, and you remained, always the figure waving goodbye, receding into the distance with each rolling turn of the car tire that carted them off to the next phase of their lives. 
</p>
<p>
As they did, your once-saturated weeks of activity gradually diminished, metamorphosing into a monotony of interactions with your family, online classes, and occasional weekend hangouts with the friends who were still here. Around you, your friends seemed to have no problem finding an endlessly packed calendar of plans. 
</p>
<p>
Despite the mental fulfillment your new university classes would impart you with, you felt like even your learning lagged. Your ability to actively discuss what you were learning in relation to real-world situations didn’t compare to your peers. While you had no problem engaging with people in group chats and Zoom socials, you still couldn’t fathom how other first-years were channeling these short-lived, momentary interactions into full-fledged friendships.
</p>
<p>
It constantly felt like you were on the verge of being in that place where everyone assumed you were, but finding the entrance to this realm proved incessantly elusive. Nothing fundamentally new, exciting, or interesting was happening to you — at least not in the way it appeared to be happening to everyone else. You were perpetually on the periphery, missing out. 
</p>
<p>
You felt stuck, and despite the overarching halt in activities experienced by the world due to the pandemic, you felt alone in how stuck you were. 
</p>
<p>
I know you want to say that this period has had a definitive, merry conclusion, and that you emerged from a brighter place within yourself, with a less critical self-outlook. While I do believe that you are in a better, more enlightened and, perhaps, less critical place, I don’t view this moment as having been concluded, signed, and sealed. 
</p>
<p>
Truthfully, it is an ongoing flux, an oscillation of self-esteem, and a constant alternation between feelings of contentment and inadequacy. And honestly, that’s okay. At least, it’s starting to feel like it is. 
</p>
<p>
As your first year at the University of Toronto comes to an end, your continuous penning continues. You will move from states of satiation to being not quite there yet, from feelings of belonging to a sense of exclusion. From feeling like you measure up, to feeling like you really don’t. But you no longer feel quite so disoriented by these undulations — rather, you draw a sense of comfort from their interminability. 
</p>
<p>
There is no clear cut note to end this piece on. Everything you’re learning about yourself, you do so as you go. But for all the instability and insecurity this year has brought on, you’re figuring out how to balance your footing. I know you can ride it out. 
</p>
<p>
Love,
</p>
<p>
Stacey
</p>
            </p>
        </div>
      </div>
    </div>
  )
}

export default ArticlePage

export const query = graphql`
  query ArticlePageQuery {
    file(name: {eq: "pandemic"}) {
        name
        childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
  }
}
`
